<template>
  <div class="container-list layout-wrap">
    <div class="container-header">
      <slot name="header" />
    </div>
    <div class="layout-content">
      <slot />
    </div>
    <!-- <el-pagination
      background
      @size-change="pageSizeChange"
      @current-change="pageChange"
      :current-page="pageCurrent"
      :page-sizes="[10, 20, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageCount">
    </el-pagination> -->
    <Page
      v-if="showPage"
      class="pagination"
      :current="pageCurrent"
      :page-size="pageSize"
      :total="pageCount"
      show-sizer
      show-elevator
      show-total
      placement="top"
      :page-size-opts="[10, 20, 50]"
      @on-change="pageChange"
      @on-page-size-change="pageSizeChange" />
  </div>
</template>
<script>
/**
 * @desc list 页面的container组件
 * @param pageSize 支持sync 当pageSize <= 0 时不分页
 * @param pageCurrent 支持sync
 * @param pageSizeList [10,20,...]
 * @event page-change 跳转页面时触发
 * @event page-size-change 切换pageSize时触发，此时会跳转第一页并触发page-change
 */
export default {
  name: 'ContainerList',
  props: {
    showPage: {
      type: Boolean,
      default: true
    },
    pageSize: {
      type: Number,
      default: 0
    },
    pageCurrent: {
      type: Number,
      default: 0
    },
    pageSizeList: {
      type: Array,
      default () {
        return [10, 20, 30, 40]
      }
    },
    pageCount: {
      type: Number,
      default: 0
    },

    tooltips: {
      type: Array,
      default: () => []
    },
    /**
     * 当前页table数量
     */
    currentTableCount: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      batchType: 0 // 批量操作类型
    }
  },
  computed: {
    pageNum: {
      get () {
        return this.pageCurrent
      },
      set (val) {
        this.$emit('update:pageCurrent', val)
      }
    }
  },
  methods: {
    pageChange (num) {
      this.$emit('page-change', num)
    },
    pageSizeChange (size) {
      this.$emit('page-size-change', size)
      this.$emit('update:pageSize', size)
    },
    clear () {
      this.batchType = 0
    }
  }
}
</script>
<style lang="less" scoped>
.container-header {
  margin-bottom: 20px;
}
.container-list{
  .container-header-left{
    margin-top: 10px;
  }
  .container-header-right {
    flex: none;
    margin-left: 30px;
    margin-top: 10px;
  }
  .pagination {
    display: flex;
    justify-content: right;
    margin-top: 30px;
  }
}
</style>
