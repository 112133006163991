/**
 * 表格页面混入文件
 * created by 2021-05-28
 */
export default {
  data () {
    return {
      isEmpty: false, // 是否没有数据
      isLoading: false, // 是否正在加载数据
      isSyncState: false, // 是否同步路由参数到状态

      isFilterKey: false, // 是否开启参数转换
      emptyValue: -99, // 需要转换的默认值

      tableApi: null, // 表格 API - 一个方法
      tableKey: 'list', // 后端返回的列表字段名
      tableData: [], // 列表数据

      filterData: {}, // 筛选参数
      cloneFilter: {}, // 克隆的初始筛选参数
      searchData: {}, // 搜索参数
      cloneSearch: {}, // 克隆的初始搜索参数

      lastType: 1, // 最后一次请求类型
      lastParams: {}, // 最后一次请求的参数
      pageNo: 1, // 当前已加载的页数
      pageSize: 10 // 每页加载的数据条数
    }
  },

  created () {
    this.cloneFilter = Object.assign({}, this.filterData)
    this.cloneSearch = Object.assign({}, this.searchData)

    if (this.isSyncState) { this.queryToState() }
  },

  methods: {
    // 获取列表数据
    getTableData (params, type) {
      this.lastType = type
      this.lastParams = Object.assign({}, params)

      // 更新分页参数
      params.pageNo = this.pageNo
      params.pageSize = this.pageSize

      // 执行请求前钩子
      params = this.beforeRequest(params, type)

      // 判断是否开启参数转换
      if (this.isFilterKey) { params = this.filterQuery(params) }

      this.isLoading = true

      if (this.isSyncState) { this.stateToQuery(params) }

      this.tableApi(params).then(result => {
        this.isLoading = false

        let list = []
        let data = result.resultData || {}
        if (result.resultStatus) {
          list = data[this.tableKey] || [] // 取出列表数据
        }

        // 执行请求后钩子
        list = this.afterResponse(list, data)
        this.tableData = list
        this.isEmpty = (list.length === 0)
      })
    },
    // 重置页码，通过筛选参数获取数据
    getDataByFilter () {
      let params = Object.assign({}, this.filterData)
      this.pageNo = this.filterData.pageNo || 1
      this.pageSize = this.filterData.pageSize || 10
      this.getTableData(params, 1)
    },
    // 重置页码，通过搜索参数获取数据
    getDataBySearch () {
      let params = Object.assign({}, this.searchData)
      this.pageNo = 1
      this.getTableData(params, 2)
    },

    // 请求指定页码的数据
    changePageNo (num) {
      this.pageNo = num
      this.filterData.pageNo = num
      this.getTableData(this.lastParams, this.lastType)
    },
    // 重置所有参数
    resetTableParams () {
      this.filterData = this.cloneFilter
      this.searchData = this.cloneSearch
    },
    // 更改每页数量
    changePageSize (size) {
      this.pageNo = 1
      this.pageSize = size
      this.filterData.pageNo = 1
      this.filterData.pageSize = size
      this.getTableData(this.lastParams, this.lastType)
    },
    // 刷新表格数据 - 不重置页码和参数
    refreshTable () {
      this.getTableData(this.lastParams, this.lastType)
    },

    // 路由查询参数同步到 filterData 和 searchData
    queryToState () {
      let query = Object.assign({}, this.$route.query)
      // 调用同步状态前钩子 - 可对查询参数进行处理
      query = this.beforeSyncState(query)
      Object.keys(query).forEach(key => {
        if (this.filterData.hasOwnProperty(key)) {
          this.filterData[key] = query[key]
        }
        if (this.searchData.hasOwnProperty(key)) {
          this.searchData[key] = query[key]
        }
      })
    },
    // 接口请求参数同步到路由
    stateToQuery (params) {
      let whiteList = ['pageNo', 'pageSize']
      let newQuery = {}
      // 调用同步路由前钩子 - 可对参数进行处理
      params = this.beforeSyncQuery(params)
      Object.keys(params).forEach(key => {
        if (!whiteList.includes(key)) {
          newQuery[key] = params[key]
        }
      })
      // 更新路由
      this.$router.replace({
        path: this.$route.path,
        query: newQuery
      }).catch(err => err)
    },

    // 对需要转换的参数 重新赋值
    filterQuery (params) {
      Object.keys(params).forEach(key => {
        if (params.hasOwnProperty(key)) {
          if (params[key] === this.emptyValue) {
            params[key] = ''
          }
        }
      })
      return params
    },

    // 请求前钩子
    beforeRequest (params) {
      return params
    },
    // 响应后钩子 - 在对 tableData 赋值前执行
    afterResponse (list) {
      return list
    },
    // 同步状态前钩子
    beforeSyncState (query) {
      return query
    },
    // 同步路由前钩子
    beforeSyncQuery (params) {
      return params
    }
  }
}
