<template>
  <div id="mian" class="container">
    <ContainerList
      ref="container"
      :page-size="filterData.pageSize"
      :page-current.sync="filterData.pageNo"
      :page-count="totalNum"
      @page-change="changePageNo"
      @page-size-change="changePageSize"
    >
      <div slot="header">
        <div class="con_from">
          <div class="lineP">
            <div class="item">
              <i>分账方</i>
              <Input
                v-model="filterData.keyword"
                suffix="ios-search"
                size="large"
                maxlength="128"
                placeholder="分账方名称/编号"
              />
            </div>
            <div class="item">
              <i>分账方属性</i>
              <Select v-model="filterData.allocMchType" size="large">
                <Option
                  v-for="item in allocMchTypeOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
            <div class="item">
              <i>所属商户</i>
              <Select v-model="filterData.mchId" size="large">
                <Option
                  v-for="item in mchDropOptions"
                  :value="item.mchId"
                  :key="item.mchId"
                  >{{ item.mchName }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="lineP">
            <div class="item">
              <i>分账方状态</i>
              <Select v-model="filterData.mchStatus" size="large">
                <Option
                  v-for="item in mchStatusOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
            <div class="item">
              <i>认证状态</i>
              <Select v-model="filterData.authStatus" size="large">
                <Option
                  v-for="item in authStatusOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
            <div class="item">
              <i>签约状态</i>
              <Select v-model="filterData.signStatus" size="large">
                <Option
                  v-for="item in signStatusOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="lineP">
            <div class="item">
              <i>图片审核状态</i>
              <Select v-model="filterData.approveStatus" size="large">
                <Option
                  v-for="item in approveStatusOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
            <div class="item date-picker">
              <i>创建时间</i>
              <div style="width: calc(100% - 60px - 10px); display: flex">
                <DatePicker
                  v-model="filterData.startTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  :clearable="true"
                  :editable="false"
                  :options="startDatePicker"
                  placeholder="选择时间"
                >
                </DatePicker>
                <span style="padding-left: 10px; padding-right: 10px">-</span>
                <DatePicker
                  v-model="filterData.endTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  :clearable="true"
                  :editable="false"
                  :options="endDatePicker"
                  placeholder="选择时间"
                  @on-change="endTimeChange"
                >
                </DatePicker>
              </div>
            </div>
            <div class="itembtn">
              <Button class="searchBt" @click="getDataByFilter">查询</Button>
              <button
                v-if="activateStatus !== 'FROZEN'"
                class="outputBt"
                v-auth="'BASIC:FILEMANA:ACCOUNTLIST/ADD'"
                @click="handleClickAdd"
                style="margin-left: 20px"
              >
                新增
              </button>
            </div>
          </div>
        </div>
      </div>
      <Table
        row-key
        class="table"
        :columns="tableColumns"
        :data="tableData"
        :loading="isLoading"
      >
        <template slot="action" slot-scope="{ row }">
          <a v-auth="'BASIC:FILEMANA:ACCOUNTLIST/DETAIL'" @click="toDetail(row)"
            >详情</a
          >
        </template>
      </Table>
    </ContainerList>
  </div>
</template>

<script>
import ContainerList from "@/components/ContainerList/index";
import tableMixin from "@/mixins/tableMixin";
import { allocmerchantsLists } from "@/api/basic/fileManage.js";
import { merchantsDrop } from "@/api/common.js";
import { formatDate } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  components: { ContainerList },
  mixins: [tableMixin],
  data() {
    return {
      activateStatus: "",
      filterData: {
        keyword: "",
        mchId: -1,
        allocMchType: -1,
        mchStatus: -1,
        signStatus: -1,
        authStatus: -1,
        approveStatus: -1,
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
      },

      dateRange: [],
      dateOptions: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now();
        },
      },
      timeRange: [],
      startDatePicker: this.startDate(),
      endDatePicker: this.endData(),

      totalNum: 0,
      isFilterKey: true, // 是否开启参数转换
      emptyValue: -1,
      tableLoading: false,
      tableApi: allocmerchantsLists,
      tableKey: "merchants",
      tableColumns: [
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          minWidth: 170,
        },
        {
          key: "allocMchName",
          align: "center",
          minWidth: 210,
          renderHeader: (h) => {
            return h("div", [h("p", "分账方名称"), h("p", "分账方编号")]);
          },
          render(h, { row }) {
            return h("div", [
              h("p", `${row.allocMchName || ""}`),
              h("p", `${row.allocMchId || ""}`),
            ]);
          },
        },
        {
          title: "分账方属性",
          key: "allocMchType",
          align: "center",
          minWidth: 170,
          render(h, { row }) {
            let map = {
              PLATALLOC: "平台分账方",
              AGENTALLOC: "服务商分账方",
            };
            return h("p", `${map[row.allocMchType] || ""}`);
          },
        },
        {
          title: "所属商户",
          key: "mchName",
          align: "center",
          minWidth: 210,
          render(h, { row }) {
            return h(
              "p",
              `${(row.allocMchType !== "AGENTALLOC" && row.mchName) || ""}`
            );
          },
        },
        {
          title: "分账方状态",
          key: "mchStatus",
          align: "center",
          minWidth: 90,
          render(h, { row }) {
            let map = {
              NOTACTIVATE: "未激活",
              ACTIVATE: "已激活",
              FROZEN: "已冻结",
              CLOSED: "已关闭",
            };
            return h("p", `${map[row.mchStatus] || ""}`);
          },
        },
        {
          title: "认证状态",
          key: "authStatus",
          align: "center",
          minWidth: 90,
          render(h, { row }) {
            let map = {
              UNAUTHORIZED: "未认证",
              AUTHENTICATING: "认证中",
              AUTH_SUCCESSED: "认证成功",
              AUTH_FAILED: "认证失败",
            };
            return h("p", `${map[row.authStatus] || ""}`);
          },
        },
        {
          title: "签约状态",
          key: "signStatus",
          align: "center",
          minWidth: 90,
          render(h, { row }) {
            let map = {
              NOT_SIGNED: "未签约",
              SIGNED: "签约成功",
            };
            return h("p", `${map[row.signStatus] || ""}`);
          },
        },
        {
          title: "图片审核状态",
          key: "approveStatus",
          align: "center",
          minWidth: 90,
          render(h, { row }) {
            let map = {
              NOT_UPLOAD: "未上传",
              NOT_APPROVED: "未审批",
              PASSED: "审批通过",
              NOT_PASSED: "审批未通过",
            };
            return h("p", `${map[row.approveStatus] || ""}`);
          },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          slot: "action",
          minWidth: 90,
        },
      ],
      tableData: [],

      allocMchTypeOptions: [
        { label: "全部", value: -1 },
        { label: "平台分账方", value: "PLATALLOC" },
        { label: "服务商分账方", value: "AGENTALLOC" },
      ],
      mchDropOptions: [],
      approveStatusOptions: [
        { label: "全部", value: -1 },
        { label: "未上传", value: "NOT_UPLOAD" },
        { label: "未审批", value: "NOT_APPROVED" },
        { label: "审批通过", value: "PASSED" },
        { label: "审批未通过", value: "NOT_PASSED" },
      ],
      mchStatusOptions: [
        { label: "全部", value: -1 },
        { label: "未激活", value: "NOTACTIVATE" },
        { label: "已激活", value: "ACTIVATE" },
        { label: "已冻结", value: "FROZEN" },
        { label: "已关闭", value: "CLOSED" },
      ],
      authStatusOptions: [
        { label: "全部", value: -1 },
        { label: "未认证", value: "UNAUTHORIZED" },
        { label: "认证中", value: "AUTHENTICATING" },
        { label: "认证成功", value: "AUTH_SUCCESSED" },
        { label: "认证失败", value: "AUTH_FAILED" },
      ],
      signStatusOptions: [
        { label: "全部", value: -1 },
        { label: "未签约", value: "NOT_SIGNED" },
        { label: "签约成功", value: "SIGNED" },
      ],
    };
  },
  computed: {
    ...mapState({
      accountListQuery: "file_account",
    }),
  },
  filters: {
    filterStatus(val, key) {
      let map = {};
      switch (key) {
        case "mchStatus":
          map = {
            NOTACTIVATE: "未激活",
            ACTIVATE: "已激活",
            FROZEN: "已冻结",
            CLOSED: "已关闭",
          };
          break;
        case "authStatus":
          map = {
            UNAUTHORIZED: "未认证",
            AUTHENTICATING: "认证中",
            AUTH_SUCCESSED: "认证成功",
            AUTH_FAILED: "认证失败",
          };
          break;
        case "signStatus":
          break;
      }
      return map[val];
    },
  },
  created() {
    if (this.accountListQuery) {
      this.filterData = this.accountListQuery;
    }
    this.getMerchantsDrop();
    this.getDataByFilter();

    const { activateStatus } = JSON.parse(sessionStorage.getItem("userInfo"));
    this.activateStatus = activateStatus;
  },
  destroyed() {
    this.setAccountListQuery(this.filterData);
  },
  methods: {
    ...mapMutations({
      setAccountData: "set_account_data",
      setAccountListQuery: "file_setAccount",
    }),
    beforeRequest(params) {
      if (params.startTime) {
        params.startTime = formatDate(params.startTime, "yyyy-MM-dd hh:mm:ss");
      }
      if (params.endTime) {
        params.endTime = formatDate(params.endTime, "yyyy-MM-dd hh:mm:ss");
      }
      return params;
    },
    afterResponse(list, data) {
      this.totalNum = data.totalNum || 0;
      return list;
    },
    dateChange(date) {
      if (+new Date(date[0]) > +new Date(date[1])) {
        return this.$Message.error("开始日期不能大于结束日期");
      }
      if (date && date[0] && date[1]) {
        if (!this.timeRange[0] && !this.timeRange[1]) {
          this.timeRange = ["00:00:00", "23:59:59"];
        }
      }
    },
    startDate() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.endTime
            ? !(
                new Date(vm.filterData.endTime).getTime() >= time.getTime() &&
                time.getTime() >
                  new Date(vm.filterData.endTime).getTime() -
                    31 * 24 * 60 * 60 * 1000
              )
            : false;
        },
      };
    },
    endData() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.startTime
            ? !(
                new Date(vm.filterData.startTime).getTime() <= time.getTime() &&
                time.getTime() <
                  new Date(vm.filterData.startTime).getTime() +
                    31 * 24 * 60 * 60 * 1000
              )
            : true;
        },
      };
    },
    //创建时间改变时
    endTimeChange(time, type) {
      if (type == "date" && time.slice(-8) === "00:00:00") {
        this.filterData.endTime = time.slice(0, -8) + "23:59:59";
      }
    },
    getMerchantsDrop() {
      merchantsDrop().then((res) => {
        if (res && res.resultStatus) {
          this.mchDropOptions = [
            { mchId: -1, mchName: "全部" },
            ...res.resultData,
          ];
        }
      });
    },
    handleClickAdd() {
      this.setAccountData(null);
      this.$router.push({
        name: "addAccount",
        query: {
          type: "add",
        },
      });
    },

    toDetail(row) {
      this.$router.push({
        name: "accountDetail",
        query: {
          id: row.allocMchId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.container {
  background: #fff;
  .table {
    min-height: 700px;
  }
  .dateline {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.lineP .date-picker {
  width: 32%;
}
.con_from .lineP .item > i {
  width: 120px;
  text-align: right;
  margin-right: 10px;
}
</style>
