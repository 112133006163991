export function formatDate(thistime, fmt = 'yyyy-MM-dd') {
	let $this = new Date(thistime)
	let o = {
		'M+': $this.getMonth() + 1,
		'd+': $this.getDate(),
		'h+': $this.getHours(),
		'm+': $this.getMinutes(),
		's+': $this.getSeconds(),
		'q+': Math.floor(($this.getMonth() + 3) / 3),
		'S': $this.getMilliseconds()
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, ($this.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
		}
	}
	return fmt
}

export function FormatTime(t,date){
    var date=new Date(date);
    var o = {   
        "M+" : date.getMonth()+1,                 //月份
        "d+" : date.getDate(),                    //日
        "h+" : date.getHours(),                   //小时
        "m+" : date.getMinutes(),                 //分
        "s+" : date.getSeconds(),                 //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S"  : date.getMilliseconds()             //毫秒
    };   
    if(/(y+)/.test(t)){
        t=t.replace(RegExp.$1,(date.getFullYear()+"").substr(4-RegExp.$1.length)); 
    };    
    for(var k in o){
        if(new RegExp("("+ k +")").test(t)){
            t=t.replace(RegExp.$1,(RegExp.$1.length==1)?(o[k]):(("00"+ o[k]).substr((""+o[k]).length))); 
        }; 
    }
    return t; 
}


export function formatValues(options) {
  let result = {}
  options.forEach(item => {
    result[item.value] = item.label
  })
  return result
}

// 验证为数字并且最多保留小数点后两位 
export function isTwoDecimal(num) {
	const reg = /^\d+(\.\d{0,2})?$/; //限制小数后两位
	return reg.test(num)
}
//验证是否为正整数  
export function isNumber(num) {
	const reg = /^[0-9]\d*$/; //有0的整数
	return reg.test(num)
}

//解决做乘法运算出现精度丢失
export function accMul(arg1, arg2) { 
	var m = 0,
			s1 = arg1.toString(),
			s2 = arg2.toString();
	try {
			m += s1.split(".")[1].length
	} catch (e) {}
	try {
			m += s2.split(".")[1].length
	} catch (e) {}
	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}


// 加法解决精度丢失
export function addd(arg1, arg2){ 
  arg1 = arg1?arg1:0
  arg2 = arg2?arg2:0
  let r1, r2, m, c;
  try {
      r1 = arg1.toString().split(".")[1].length;
  }
  catch (e) {
      r1 = 0;
  }
  try {
      r2 = arg2.toString().split(".")[1].length;
  }
  catch (e) {
      r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
      let cm = Math.pow(10, c);
      if (r1 > r2) {
          arg1 = Number(arg1.toString().replace(".", ""));
          arg2 = Number(arg2.toString().replace(".", "")) * cm;
      } else {
          arg1 = Number(arg1.toString().replace(".", "")) * cm;
          arg2 = Number(arg2.toString().replace(".", ""));
      }
  } else {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
}



